import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="Footer">
            <p className="Footer__year">&copy; 2022 
                <span className="Footer__content"> myCodefolio</span>
            </p>
        </footer>
    );
}

export default Footer;